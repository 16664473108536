console.log("custom js webpack");
jQuery(document).ready(function ($) {
  $(".menu-toggle").on("click", function () {
    $(".header-menu").toggleClass("menu-open");
  });

  /* Woocommerce AJAX update cart count */
  $(document.body).on("added_to_cart", function (response) {
    updateCartCounter();
  });

  // Update cart count function
  function updateCartCounter() {
    $.ajax({
      type: "GET",
      url: wc_cart_params.ajax_url,
      data: {
        action: "get_cart_count",
      },
      success: function (response) {
        $(".cart-count").html(response);
      },
    });
  }
});

/* Move price custom */
jQuery(document).ready(function ($) {
  $("form.variations_form")
    .on("found_variation", function (event, variation) {
      if (variation.price_html) {
        $(".product_pricing").html(variation.price_html);
      }
    })
    .on("reset_data", function () {
      $(".product_pricing").html(""); // Reset price HTML when no variation is selected
    });
});

/* Product tabs custom js */
jQuery(document).ready(function ($) {
  $(document).on("click", ".tabs li", function () {
    // Get the ID of the tab content to display
    var tabName = $(this).data("tab");

    // Hide all tab content and remove "active" class from all tabs
    $(".tab-content-wrapper").hide();
    $(".tabs li").removeClass("active");

    // Show the clicked tab content and add "active" class to the clicked tab
    $("#" + tabName).show();
    $(this).addClass("active");
  });

  // Optionally, trigger a click on the first tab to make it active by default
  $(".tabs li:first").click();
});
