// jQuery(document.body).on("wc_fragments_refreshed", function () {
//   // Reinitialize Swiper after the mini-cart content is refreshed
//   var miniCartSwiper = new Swiper(".mini-cart-swiper", {
//     slidesPerView: 2.1,
//     spaceBetween: 10,
//     // mousewheel: true,
//     keyboard: {
//       enabled: true,
//     },
//     scrollbar: {
//       el: ".swiper-scrollbar",
//       // hide: true,
//     },
//     breakpoints: {
//       // For screens smaller than 768px
//       768: {
//         spaceBetween: 15,
//       },
//     },
//   });

//   // Function to adjust all slides to match the tallest one and enforce max width
//   function adjustSlideDimensions() {
//     var slides = jQuery(".mini-cart-swiper .swiper-slide");
//     var maxHeight = 0;

//     // Reset heights to auto to calculate properly
//     slides.css("height", "auto");

//     // Determine the tallest slide
//     slides.each(function () {
//       maxHeight = Math.max(maxHeight, jQuery(this).outerHeight());
//     });

//     // Apply the tallest height and max width to all slides
//     slides.css({
//       height: maxHeight + "px",
//       "max-width": "150px", // Enforce maximum width
//     });
//   }

//   // Adjust dimensions on initialization
//   adjustSlideDimensions();

//   // Adjust dimensions again on Swiper resize event
//   miniCartSwiper.on("resize", adjustSlideDimensions);
// });
jQuery(document.body).on("wc_fragments_refreshed", function () {
  var miniCartSwiper = new Swiper(".mini-cart-swiper", {
    slidesPerView: 2.1,
    spaceBetween: 10,
    keyboard: {
      enabled: true,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
    },
    breakpoints: {
      768: {
        spaceBetween: 15,
      },
    },
  });

  // Function to adjust all slides to match the tallest one and enforce max width
  function adjustSlideDimensions() {
    var slides = jQuery(".mini-cart-swiper .swiper-slide");
    var maxHeight = 0;

    slides.css("height", "auto");
    slides.each(function () {
      maxHeight = Math.max(maxHeight, jQuery(this).outerHeight());
    });

    slides.css({
      height: maxHeight + "px",
      "max-width": "150px",
    });
  }

  adjustSlideDimensions();
  miniCartSwiper.on("resize", adjustSlideDimensions);

  // Add event listeners for Swiper interaction
  miniCartSwiper.on("touchStart", function () {
    window.isSwiperInteracting = true; // Use global variable
  });

  miniCartSwiper.on("touchEnd", function () {
    setTimeout(() => {
      window.isSwiperInteracting = false; // Use global variable
    }, 100);
  });
});
