jQuery(document).ready(function ($) {
  /**
   * Function to check stock conditions and update button text
   */
  function updateButtonBasedOnStock(variation) {
    let addToCartButton = $(".single_add_to_cart_button");
    let stockQuantityField = $(
      `.stock_quantity_variation[data-variation-id='${variation.variation_id}']`
    );
    let backorderLimitField = $(
      `.backorder_limit_variation[data-variation-id='${variation.variation_id}']`
    );
    let backorderAllowedField = $(
      `.backorder_allowed_variation[data-variation-id='${variation.variation_id}']`
    );

    let stockQuantity = stockQuantityField.length
      ? parseInt(stockQuantityField.val())
      : 0;
    let backorderLimit = backorderLimitField.length
      ? parseInt(backorderLimitField.val())
      : 50;
    let isBackorderAllowed =
      backorderAllowedField.length && backorderAllowedField.val() === "yes";

    // Set the default button text to "Add to Cart"
    let buttonText = MY_CUSTOM_CART_TEXT.add_to_cart;

    // Log values for debugging
    // console.log("Stock Quantity:", stockQuantity);
    // console.log("Backorder Limit:", backorderLimit);
    // console.log("Backorder Allowed:", isBackorderAllowed);

    if (
      stockQuantity > 0 ||
      (stockQuantity <= 0 &&
        isBackorderAllowed &&
        stockQuantity > -backorderLimit)
    ) {
      // In stock or within backorder limit
      buttonText = MY_CUSTOM_CART_TEXT.add_to_cart;
      addToCartButton
        .prop("disabled", false)
        .removeClass("disabled backorder-limit");
    } else if (
      stockQuantity <= 0 &&
      isBackorderAllowed &&
      stockQuantity <= -backorderLimit
    ) {
      // Backorder limit reached
      buttonText = variation.waitlist_enabled
        ? "Join Waitlist"
        : MY_CUSTOM_CART_TEXT.out_of_stock;
      addToCartButton
        .prop("disabled", true)
        .addClass("disabled backorder-limit");
    } else {
      // Out of stock and no backorders allowed
      buttonText = MY_CUSTOM_CART_TEXT.out_of_stock;
      addToCartButton
        .prop("disabled", true)
        .addClass("disabled backorder-limit");
    }

    // Update the button text
    addToCartButton.text(buttonText);
  }

  /**
   * Handle button update for variable products
   */
  $(".variations_form").on("show_variation", function (event, variation) {
    updateButtonBasedOnStock(variation);
  });

  /**
   * Ensure button text reset if no variation selected
   */
  $(".variations_form").on("woocommerce_variation_select_reset", function () {
    $(".single_add_to_cart_button")
      .text(MY_CUSTOM_CART_TEXT.select_options)
      .prop("disabled", true)
      .removeClass("backorder-limit");
  });

  /**
   * Handle simple products backorder limit
   */
  if ($(".single-product").length && $("#backorder_limit").length) {
    let stockQuantity = parseInt($("#stock_quantity").val());
    let backorderLimit = parseInt($("#backorder_limit").val());
    let addToCartButton = $(".single_add_to_cart_button");

    if (stockQuantity <= -backorderLimit) {
      addToCartButton
        .prop("disabled", true)
        .addClass("disabled backorder-limit")
        .text(MY_CUSTOM_CART_TEXT.out_of_stock);
    }
  }
});
