const timelineSwiper = new Swiper(".timeline-swiper", {
  slidesPerView: 1.5,
  spaceBetween: 0,
  centeredSlides: true,
  keyboard: {
    enabled: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    640: {
      slidesPerView: 5,
      spaceBetween: 0,
      centeredSlides: true,
      initialSlide: 2,
    },
  },
});
